<template>
  <b-card class="login-body mb-5">
    <h3 class="text-center mt-4 mb-4">{{$t('ACCOUNT.LOGIN_BANKID')}}</h3>
    <form>

      <div v-if="show_qr" id="qr-container">
        <img style="margin:auto; width: 60%;" align-h="center" :src="require('@/assets/images/BankID_MasterBrand/BankID_logo_black.png')" class="img-fluid mb-8" alt="bankid" />

        
        <p>{{ $t('ACCOUNT.START_BANKID_QR') }}</p>

        <img :src="qr_url" style="margin:auto;" align-h="center" />
        
      </div>
      
      <div v-if="show_options" id="qr-container">
        <img style="margin:auto; width: 60%;" align-h="center" :src="require('@/assets/images/BankID_MasterBrand/BankID_logo_black.png')" class="img-fluid mb-8" alt="bankid" />

        <b-button style="margin: auto; width:80%;" variant="primary" @click.prevent="mobile_bankid_clicked">{{ $t('ACCOUNT.MOBILE_BANKID') }}</b-button>
        
        <div v-if="show_open_bankid_spinner" class="d-flex justify-content-center mb-8 mt-8">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-button v-else style="margin: auto; margin-top: 20px; width:80%;" variant="outline-primary" @click.prevent="open_bankid_clicked">{{ $t('ACCOUNT.OPEN_BANKID') }}</b-button>
      </div>

      
      <div v-if="show_user_not_found" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text"><p>{{$t('ACCOUNT.NO_USER')}}</p><p>{{$t('ACCOUNT.NO_USER_INFO')}}</p></div>
      </div>
      
      <div v-if="show_no_permissions" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text"><p>{{$t('ACCOUNT.NO_ACCESS_INFO1')}}</p><p>{{$t('ACCOUNT.NO_ACCESS_INFO2')}}</p></div>
      </div>
      
      <b-alert
        :show="showWarning"
        variant="danger"
        dismissible
        @dismissed="showWarning = false"
        fade
        >{{$t('ACCOUNT.BANKID_ERROR')}}</b-alert
      >

    </form>
  </b-card>
</template>

<style lang="scss" scoped>

#qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { mapState } from 'vuex';

import axios from 'axios';

import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import store from '@/core/services/store';
import { is_mobile } from '@/core/services/utils';

import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { isPersonnrValid } from '@/core/services/validators';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { MEMBER_LOGIN, MEMBER_LOGOUT, MEMBER_SET_COMPANY, MEMBER_SET_LOGINLINK } from '@/core/services/store/member.auth.module';

export default {
  mixins: [ validationMixin ],
  name: 'MemberBankIdLoginComponent',
  props: ['redirect'],
  data() {
    return {
      debug: false,
      collect_data: null,
      form: {
        personnr: null
      },

      show_options: true,
      show_qr: false,
      show_user_not_found: false,
      show_open_bankid_spinner: false,

      interval_id: null,
      qr_url: null,
      showWarning: false,
      show_no_permissions: false
    };
  },

  validations() {
    return {
      form: {
        personnr: {
          required: true,
          validate_personnr: validate_personnr,
          isPersonnrValid: isPersonnrValid
        }
      }
    };
  },
  
  async mounted() {

    this.debug = this.$route.query.debug !== undefined ? true : false;

    if (this.debug) {
      console.log('DEBUG ENABLED!');
    }

    if (!getKeyValue('member_bankid_auth_token')) {
      this.member_bankid_auth_token = this.randomstr(32);
      setKeyValue('member_bankid_auth_token', this.member_bankid_auth_token);
    }
    else {
      this.member_bankid_auth_token = getKeyValue('member_bankid_auth_token');
    }
    
    if (this.interval_id) {
      clearInterval(this.interval_id);
    }

  },

  destroyed() {
    clearInterval(this.interval_id);
  },
  
  methods: {

    start_bankid_polling() {

      if (this.interval_id) {
        clearInterval(this.interval_id);
      }

      this.interval_id = setInterval(async () => {
        this.qr_url = await this.get_qr_bankid();
        this.collect_data = await this.get_bankid_collect();
      }, 4000);
    },

    randomstr(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },

    validate_state(name) {
      const personnr = this.$v.form.personnr.$model;

      if (personnr === null) {
        return null;
      }

      let valid = formatPersonnr(personnr);

      return valid.valid;
    },

    async mobile_bankid_clicked() {

      try {
        this.show_qr = true;
        this.show_options = false;

        this.qr_url = await this.get_qr_bankid();

        this.start_bankid_polling();
      }
      catch (err) {
        console.error('mobile_bankid_clicked', err);
      }

    },

    forward_to_bankid(auto_start_token, return_url) {
      console.log('forward_to_bankid', auto_start_token, return_url);
      try {
        location.href = `bankid:///?autostarttoken=${auto_start_token}&redirect=${return_url}`;
      }
      catch (err) {
        location.href = `https://app.bankid.com/?autostarttoken=${auto_start_token}&redirect=${return_url}`;
      }

    },

    async open_bankid_clicked() {
      try {
        this.show_open_bankid_spinner = true;

        const data = await this.get_bankid_auth(false);

        if (data) {
          const return_url = get_base_url() + '/v2/member-bankid-return?order_ref=' + data.order_ref;

          setKeyValue('bankid_order_ref', data.order_ref);

          this.start_bankid_polling();

          this.forward_to_bankid(data.auto_start_token, return_url);
          
          return;
        }

        console.error('bankid error data');

        this.show_open_bankid_spinner = false;
      }
      catch (err) {
        console.error(err);

        this.show_open_bankid_spinner = false;
      }
      
    },

    async get_qr_bankid() {
      try {
        const data = await this.get_bankid_auth(true);

        if (data) {
          return data.qr_url;
        }
        
      }
      catch (err) {
        console.error(err);
      }

      return null;
    },

    async get_bankid_auth(qr) {
      try {
        const res = await axios.get(`/bankid/memberauth?qr=${qr ? 'true' : 'false'}&token=${this.member_bankid_auth_token}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error(err);
      }

      return null;
    },

    async get_bankid_collect() {
      try {
        const res = await axios.get(`/bankid/collectstatus?intent=memberlogin&token=${this.member_bankid_auth_token}`);

        if (res.status === 200) {

          if (res.data.status === 'authenticated') {
            console.log('success authentication');

            if (this.debug) {
              console.log('success data', res.data);
              return;
            }

            this.$store.dispatch(MEMBER_LOGIN, res.data);

            if (this.redirect) {
              this.$router.push({ name: this.redirect });
            }
            else {
              this.$router.push({ name: 'profile' });
            }
          }
          else if (res.data.status === 'no_access') {
            console.log('no_access', res.data);
            this.show_no_permissions = true;
            this.show_options = false;
            this.show_qr = false;
          }
          else {
            console.log('not authenticated', res.data);
          }

          return res.data;
        }
        else if (res.status === 404) {
          console.log('status 404 user not found');
          /// when the user wasnt found
          this.show_user_not_found = true;
          this.show_options = false;
          this.show_qr = false;

        }
      }
      catch (err) {
        console.error(err);
      }

      return null;
    }



  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
